import Fade from 'react-reveal/Fade';
import Roll from 'react-reveal/Roll';

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <Fade cascade duration={1500}>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/Software-Development-image.png" className="rounded" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <h3>Why Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <Roll left cascade delay={500}><ul>

                      {props.data
                        ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}


                    </ul></Roll>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <Roll left cascade delay={1000}>
                     <ul>
                        {props.data
                          ? props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                          : "loading"}
                      </ul>
                    </Roll>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

        </div>
      </div>
    </div>
  );
};

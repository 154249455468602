import Fade from 'react-reveal/Fade';

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>

        <Fade bottom cascade>
          <div className="row">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <div className="testimonial">
                      <div className="testimonial-image">
                        <img src={d.img} alt={`${d.name}`} />
                      </div>
                      <div className="testimonial-content">
                        <p>"{d.text}"</p>
                        <div className="testimonial-meta">- {d.name}</div>
                      </div>
                    </div>
                  </div>
                ))
              : 'loading'}
          </div>
        </Fade>
      </div>
    </div>
  );
};

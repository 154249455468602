const grandalat_data = {
  "Header": {
      "title": "Innovative Custom Software & Educational Solutions",
      "paragraph": "At Grandalat Solutions, we craft tailored software, mobile and web applications, databases, stunning UI/UX designs, and robust digital marketing strategies. Our latest venture extends our expertise to the education sector, delivering transformative technology solutions to empower learners and educators alike."
    },
    "About": {
      "paragraph": "Grandalat Digital Agency is a trailblazing company driven by a team of visionary professionals with over five years of collective excellence. Rooted in Nigeria and serving a diverse range of industries, we specialize in delivering bespoke solutions that harmonize creativity, technology, and strategic innovation. Our mission is clear: to help businesses and educational institutions unlock new opportunities, engage meaningfully with their audiences, and achieve sustainable growth through technology-driven excellence.",
      "Why": [
        "We build enduring partnerships founded on trust, innovation, and shared goals.",
        "Our commitment to swift and precise execution ensures your vision becomes a reality without delay.",
        "Our team thrives on pushing boundaries, channeling creativity, and delivering excellence where it matters most."
      ],
      "Why2": [
        "We leverage the latest technologies and services to design solutions tailored to your unique challenges.",
        "We take ownership of your success, applying diligence, insight, and strategic problem-solving at every step.",
        "We bring your ideas to life with unparalleled creativity, turning concepts into impactful realities."
      ],
      "Education": {
        "paragraph": "At Grandalat, we are revolutionizing education through technology. Our passion for learning and innovation drives us to create solutions that inspire growth and excellence in schools, students, and educators. From developing interactive educational games to implementing school management systems and conducting specialized training programs, we bridge the gap between technology and learning. We envision a future where education is redefined by creativity, accessibility, and purpose-driven solutions."
      }
    },
    "Gallery": [
      {
        "title": "Cooperative Savngs and Loan System",
        "largeImage": "img/portfolio/01-large.png",
        "smallImage": "img/portfolio/01-small.png"
      },/*
      {
        "title": "Traffic Offense System",
        "largeImage": "img/portfolio/02-large.jpg",
        "smallImage": "img/portfolio/02-small.jpg"
      },*/
      {
        "title": "Hospital service/activity management application",
        "largeImage": "img/portfolio/03-large.jpg",
        "smallImage": "img/portfolio/03-small.jpg"
      },
      
      {
        "title": "School Management System",
        "largeImage": "img/portfolio/schooled.png",
        "smallImage": "img/portfolio/schooled.png"
      },
      {
        "title": "Nigerian Police Force",
        "largeImage": "img/portfolio/policed.jpg",
        "smallImage": "img/portfolio/policed.jpg"
      },
      {
        "title": "E-Inventory System",
        "largeImage": "img/portfolio/evs.png",
        "smallImage": "img/portfolio/evs.png"
      },
      {
        "title": "Interior Design Website",
        "largeImage": "img/portfolio/interno.png",
        "smallImage": "img/portfolio/interno.png",
      },
      {
        "title": "Justshup.com",
        "largeImage": "img/portfolio/justshup.png",
        "smallImage": "img/portfolio/justshup.png",
      },
    ],
    "Services": [
    {
      "icon": "fa fa-mobile",
      "name": "Mobile App Development",
      "text": "We specialize in crafting intuitive and feature-rich mobile applications for iOS and Android platforms, optimized for both phones and tablets. Whether native or hybrid, we deliver seamless performance, ensuring your app reaches its full potential across all devices."
    },
    {
      "icon": "fa fa-cart-arrow-down",
      "name": "E-commerce Solutions",
      "text": "Our team excels in building dynamic, responsive e-commerce platforms that provide exceptional user experiences across mobile, tablet, and desktop devices. From sleek layouts to secure payment systems, we tailor each solution to drive sales and enhance customer satisfaction."
    },
    {
      "icon": "fa fa-desktop",
      "name": "Desktop Application Development",
      "text": "We design and develop powerful desktop applications, from Windows-based systems to cross-platform solutions, tailored to meet your unique business requirements. Our applications offer reliability, scalability, and user-friendly interfaces."
    },
    {
      "icon": "fa fa-graduation-cap",
      "name": "School Learning Management Software",
      "text": "Empowering schools with cutting-edge Learning Management Software (LMS) designed to streamline operations and enhance learning experiences. Our LMS supports student performance tracking, classroom assessments, homework assignments, attendance management, and parent-teacher communication, driving educational excellence."
    },
    {
      "icon": "fa fa-heart",
      "name": "Creative Graphics Design",
      "text": "Transform your brand with stunning graphic designs, including logos, banners, flyers, and ceremonial creatives. Our visually compelling designs are tailored to captivate your audience and enhance your brand identity."
    },
    {
      "icon": "fa fa-bullhorn",
      "name": "Advertisement Campaigns",
      "text": "We create impactful advertisements to elevate your product or business visibility. From concept to execution, we deliver attention-grabbing online ads designed to maximize your reach and engagement."
    },
    {
      "icon": "fa fa-pie-chart",
      "name": "Project Support & Maintenance",
      "text": "Our expert team provides comprehensive support and maintenance for your projects. We ensure your systems stay up-to-date and perform optimally, with the flexibility to implement new features and updates as needed."
    }
  ],
    "Testimonials": [
      {
        "img": "img/testimonials/avatar-m.svg",
        "text": "\"Love the professionalism\"",
        "name": "Micheal"
      },
      {
        "img": "img/testimonials/avatar-f.svg",
        "text": "\"Satisfied with the service rendered \"",
        "name": "Debbie"
      },
      {
        "img": "img/testimonials/lammy.jpg",
        "text": "\"They did their best and also made sure the work was done 100 percent \"",
        "name": "Olamide"
      },
      {
        "img": "img/testimonials/avatar-m.svg",
        "text": "\"They make sure the work is done, and also do their best to satisfy its customers \"",
        "name": "Anthony"
      },
      {
        "img": "img/testimonials/sharon.jpg",
        "text": "\"I'm satisfied with the service offered during my project. \"",
        "name": "Sharon"
      },
      {
        "img": "img/testimonials/prospect-school-logo.jpeg",
        "text": "\"The team exceeded our expectations and ensured the system was tailored to our school's needs.\"",
        "name": "Prospect Private School"
      },
      {
        "img": "img/testimonials/esopat-school.jpg",
        "text": "\"Exceptional service and support. Our school operations have greatly improved.\"",
        "name": "Esopat School"
      }
    ],
    "Team": [
      {
        "img": "img/team/preshie.jpg",
        "name": "Precious",
        "job": "Software Developer/ C.E.O"
      },
      {
        "img": "img/team/davo.jpg",
        "name": "David",
        "job": "Lead Software Developer/ C.T.O"
      }
    ],
    "Contact": {
      "address": "Based in Ibadan and Lagos, offering seamless online services.",
      "phone": "+2349158578566",
      "email": "grandalat@gmail.com",
      "facebook": "fb.com",
      "twitter": "twitter.com",
      "youtube": "youtube.com"
    },
    "Features": [
      {
        "icon": "fa fa-comments-o",
        "title": "Customer-Centric Communication",
        "text": "We prioritize clear, professional, and responsive communication to foster strong relationships and deliver tailored solutions that exceed expectations."
      },
      {
        "icon": "fa fa-graduation-cap",
        "title": "Empowering Education",
        "text": "Revolutionize learning with our School Learning Management Software, a comprehensive platform for managing student progress, assessments, attendance, and parent-teacher collaboration, all designed to enhance academic excellence."
      },
      {
        "icon": "fa fa-bullhorn",
        "title": "Astonishing Branding",
        "text": "We infuse creativity and inspiration into your brand, crafting memorable experiences that resonate with your audience and elevate your business identity."
      },
      {
        "icon": "fa fa-group",
        "title": "Dynamic Teamwork",
        "text": "Our collaborative and innovative team works in harmony, combining expertise and passion to deliver outstanding results for every project."
      },
      {
        "icon": "fa fa-magic",
        "title": "Unleashing Creativity",
        "text": "From ideation to execution, our imaginative approach transforms your concepts into extraordinary products and solutions that captivate and inspire."
      }
    ],
    "Edstream": {
      "paragraph": "Introducing Nexilum, a groundbreaking Learning Management Software designed to transform the way education is delivered and experienced. With cutting-edge tools and intuitive features, Nexilum empowers educators and students to achieve their best. What sets Nexilum apart is its advanced evaluation system, a unique feature that tracks student performance with precision. This innovative system analyzes progress in real-time, ensuring students are fully prepared for subject tests and helping educators identify areas where extra support is needed. Nexilum is more than software—it’s a partner in creating a brighter future for learners everywhere.",
      "Why": [
        "We empower educators with intuitive tools that simplify classroom management and enhance student engagement.",
        "Our cloud-based platform ensures flexibility, allowing students to learn anytime, anywhere, on any device.",
        "Nexilum’s robust analytics provide actionable insights to improve teaching methods and track student progress effectively."
      ],
      "Why2": [
        "We integrate the latest AI-driven technologies to personalize the learning journey for every student, fostering growth and achievement.",
        "Our user-friendly interface ensures that teachers, administrators, and students can easily navigate and maximize the platform’s full potential.",
        "Nexilum is designed to scale, supporting institutions of all sizes with powerful features that adapt to evolving educational needs."
      ]
    },  
  }

export default grandalat_data;
import Zoom from 'react-reveal/Zoom';

export const Features = (props) => {
  return (
    <div id="features" className="features-section text-center py-5">
      <div className="container">
        <div className="section-title mb-4">
          <h2 className="features-title">Features</h2>
        </div>
        <div className="row">
          <Zoom left cascade>
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-12 col-sm-6 col-md-4 d-flex justify-content-center mb-4"
                  >
                    <div className="feature-card">
                      <div className="feature-icon">
                        <i className={d.icon}></i>
                      </div>
                      <div className="feature-content">
                        <h3 className="feature-title">{d.title}</h3>
                        <p className="feature-text">{d.text}</p>
                      </div>
                    </div>
                  </div>
                ))
              : 'Loading...'}
          </Zoom>
        </div>
      </div>
    </div>
  );
};

import Fade from 'react-reveal/Fade';
import Roll from 'react-reveal/Roll';

export const Edstream = ({ data }) => {
  if (!data) {
    return (
      <div style={{ textAlign: 'center', padding: '50px', fontSize: '1.5rem' }}>
        Loading...
      </div>
    );
  }

  return (
    <div
      id="education"
      style={{
        padding: '60px 20px',
        backgroundColor: '#f9f9f9',
        overflowX: 'hidden', // Prevent horizontal overflow
        overflowY: 'auto',  // Allow vertical scroll if needed
      }}
    >
      <div className="container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <div
          className="row align-items-center"
          style={{ flexWrap: 'wrap', gap: '20px' }} // Wrap content and add spacing
        >
          <Fade cascade duration={1500}>
            {/* Left Column: Image */}
            <div className="col-md-6 text-center">
              <img
                src="img/DALL·E 2024-12-20 11.18.57 - A futuristic concept image representing a premium and elegant school management software called 'Nexilum'. The scene includes a modern classroom with .webp"
                className="img-fluid rounded shadow-lg"
                alt="Education Solutions"
                style={{
                  maxWidth: '100%', // Ensure the image does not exceed the container
                  height: 'auto',
                  marginBottom: '20px',
                  objectFit: 'cover', // Scale image while maintaining aspect ratio
                }}
              />
            </div>

            {/* Right Column: Text */}
            <div className="col-md-6">
              <div
                className="education-text"
                style={{
                  lineHeight: '1.8',
                  overflowWrap: 'break-word', // Prevent long words from causing overflow
                }}
              >
                <h2 style={{ marginBottom: '20px', fontWeight: 'bold', color: '#333' }}>
                 Empowering the Future of Learning
                </h2>
                <p
                  style={{
                    fontSize: '1.5rem',
                    color: '#555',
                    marginBottom: '30px',
                    maxWidth: '100%',
                  }}
                >
                  {data.paragraph}
                </p>
                <h3 style={{ marginBottom: '20px', fontWeight: '600', color: '#222' }}>
                  Why Choose Our Educational Solutions?
                </h3>

                {/* Lists */}
                <div className="list-style row">
                  <div className="col-sm-6">
                    <Roll left cascade delay={500}>
                      <ul
                        style={{
                          listStyleType: 'disc',
                          paddingLeft: '20px',
                          marginBottom: '0',
                        }}
                      >
                        {data.Why &&
                          data.Why.map((d, i) => (
                            <li
                              key={i}
                              style={{ marginBottom: '10px', color: '#444', wordWrap: 'break-word' }}
                            >
                              {d}
                            </li>
                          ))}
                      </ul>
                    </Roll>
                  </div>
                  <div className="col-sm-6">
                    <Roll left cascade delay={1000}>
                      <ul
                        style={{
                          listStyleType: 'disc',
                          paddingLeft: '20px',
                          marginBottom: '0',
                        }}
                      >
                        {data.Why2 &&
                          data.Why2.map((d, i) => (
                            <li
                              key={i}
                              style={{ marginBottom: '10px', color: '#444', wordWrap: 'break-word' }}
                            >
                              {d}
                            </li>
                          ))}
                      </ul>
                    </Roll>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export const PrivacyPolicy = (props) => {
  return (
    <div id='privacy-policy'>
      <h2 className="text-center">Privacy Policy for Grandalat Solutions</h2>

      <p>
        At Grandalat Solutions, accessible from <a href="https://grandalat.com.ng">grandalat.com.ng</a>, safeguarding your privacy is one of our top priorities. This Privacy Policy outlines how we collect, use, and protect the information provided to us by visitors and clients, including users of our School Learning Management Software and other services.
      </p>

      <p>
        If you have any questions or require additional details about our Privacy Policy, please do not hesitate to contact us.
      </p>

      <h3>Scope of Policy</h3>
      <p>
        This Privacy Policy applies to all visitors to our website and users of our online services, including but not limited to the School Learning Management Software. It governs the collection, use, and management of data shared through our digital platforms. This policy does not cover offline activities or third-party websites linked to or advertised on our platform.
      </p>

      <h3>Consent</h3>
      <p>
        By using our website or services, you consent to this Privacy Policy and agree to its terms. 
      </p>

      <h3>Information We Collect</h3>
      <p>
        The type of information we collect depends on your interaction with our platform. This includes:
      </p>
      <ul>
        <li>
          <strong>Account Information:</strong> When registering for our services, including the School Learning Management Software, we may collect your name, email address, phone number, and other relevant details.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about your usage of our software, website, and services, such as pages visited, features accessed, and interaction logs.
        </li>
        <li>
          <strong>Communication Details:</strong> If you contact us directly, we may collect additional information, such as the content of your message or attachments.
        </li>
      </ul>

      <h3>How We Use Your Information</h3>
      <p>
        We use the information collected for the following purposes:
      </p>
      <ul>
        <li>To provide, maintain, and improve our services, including the School Learning Management Software.</li>
        <li>To personalize your experience and enhance our website's functionality.</li>
        <li>To communicate updates, new features, or promotional offers.</li>
        <li>To analyze usage trends and improve our offerings.</li>
        <li>To comply with legal obligations and prevent fraud or misuse of our platform.</li>
      </ul>

      <h3>Log Files</h3>
      <p>
        Like most websites, we use log files to track visitors. These include details such as IP addresses, browser types, referring pages, and timestamps. This data is not linked to any personally identifiable information and is used to analyze trends and optimize our platform.
      </p>

      <h3>Cookies</h3>
      <p>
        We use cookies to store user preferences and enhance your experience. You can disable cookies in your browser settings; however, this may affect the functionality of certain features on our website or software.
      </p>

      <h3>Data Security</h3>
      <p>
        We are committed to ensuring the security of your personal data. All sensitive information is protected using industry-standard encryption and security measures.
      </p>

      <h3>Third-Party Services</h3>
      <p>
        Our website and software may include integrations with third-party services, such as payment gateways or analytics tools. These services have their own privacy policies, which we recommend reviewing. Grandalat Solutions is not responsible for the practices of third-party platforms.
      </p>

      <h3>GDPR and NDPR Compliance</h3>
      <p>
        We comply with applicable data protection regulations, including the General Data Protection Regulation (GDPR) and the Nigeria Data Protection Regulation (NDPR). Users have the right to:
      </p>
      <ul>
        <li>Request access to their personal data.</li>
        <li>Request correction or deletion of inaccurate or outdated data.</li>
        <li>Restrict or object to the processing of their data under certain conditions.</li>
        <li>Request the transfer of their data to another organization.</li>
      </ul>
      <p>
        To exercise any of these rights, please contact us directly. We will respond within 30 days.
      </p>

      <h3>Children's Privacy</h3>
      <p>
        Protecting the privacy of children is important to us. We do not knowingly collect personal information from individuals under the age of 13. If you believe a child has provided us with personal information, please contact us immediately so we can take appropriate action.
      </p>

      <h3>Updates to This Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Changes will be reflected on this page, and significant updates will be communicated directly to users where applicable.
      </p>

      <p>
        If you have any concerns about this Privacy Policy or how your data is managed, please <a href="mailto:info@grandalat.com.ng">contact us</a>. We are committed to addressing your concerns and protecting your privacy.
      </p>
    </div>
  );
};
